import PenaltyUnitCalculatorIllustration from "../images/penalty-unit-calculator-illustration.svg";
import PreSentenceDetentionCalculatorIllustration from "../images/pre-sentence-detention-calculator-illustration.svg";
import SuggestionIllustration from "../images/suggestion-illustration.svg";
import toolsIllustration from "../images/tools-illustration.svg";
import commonwealthJurisdictionIllustration from "../images/commonwealth-jurisdiction-illustration.svg";
import westernAustraliaJurisdictionIllustration from "../images/western-australia-jurisdiction-illustration.svg";
import northernTerritoryJurisdictionIllustration from "../images/northern-territory-jurisdiction-illustration.svg";
import southAustraliaJurisdictionIllustration from "../images/south-australia-jurisdiction-illustration.svg";
import tasmaniaJurisdictionIllustration from "../images/tasmania-jurisdiction-illustration.svg";
import victoriaJurisdictionIllustration from "../images/victoria-jurisdiction-illustration.svg";
import newSouthWalesJurisdictionIllustration from "../images/new-south-wales-jurisdiction-illustration.svg";
import queenslandJurisdictionIllustration from "../images/queensland-jurisdiction-illustration.svg";
import jurisdicationIllustration from "../images/jurisdication-illustration.svg";
import createJurisdictionIllustration from "../images/create-jurisdiction-illustration.svg";
import penaltyUnitLegislationIllustration from "../images/penalty-unit-legislation-illustration.svg";
import createPenaltyUnitLegislationIllustration from "../images/create-penalty-unit-legislation-illustration.svg";

export const toolImages = [
  {
    id: 1,
    image: PenaltyUnitCalculatorIllustration
  },
  {
    id: 2,
    image: PreSentenceDetentionCalculatorIllustration
  },
  {
    id: 999,
    image: SuggestionIllustration
  }
];

export const defaultToolIllustration = toolsIllustration;

export const jurisdictionImages = [
  {
    id: "03d906aa-9120-4958-bdee-a265c406c27a",
    image: victoriaJurisdictionIllustration
  },
  {
    id: "107929bd-bf08-4a9f-a2c3-41e7b0079b64",
    image: newSouthWalesJurisdictionIllustration
  },
  {
    id: "2c2fbab7-6b50-4b9e-a225-12b81a39fe82",
    image: queenslandJurisdictionIllustration
  },
  {
    id: "b19e5150-3c47-4fb3-a3be-e784e01cf610",
    image: tasmaniaJurisdictionIllustration
  },
  {
    id: 5,
    image: southAustraliaJurisdictionIllustration
  },
  {
    id: 6,
    image: northernTerritoryJurisdictionIllustration
  },
  {
    id: 7,
    image: westernAustraliaJurisdictionIllustration
  },
  {
    id: "e3910729-268d-4435-83cd-110425dcc906",
    image: commonwealthJurisdictionIllustration
  },
  {
    id: 999,
    image: createJurisdictionIllustration
  }
];

export const defaultJurisdictionIllustration = jurisdicationIllustration;

export const penaltyUnitLegislationIllustrations = [
  {
    id: 999,
    image: createPenaltyUnitLegislationIllustration
  }
];

export const defaultPenaltyUnitLegislationIllustration = penaltyUnitLegislationIllustration;