import PropTypes from "prop-types";
import React from "react";

import Header from "../header";
import Footer from "../footer";

function AdminLayout({ children })
{
  return (
    <div
      className = "flex"
    >
      <div
        className = "flex flex-col font-sans w-full min-h-screen text-gray-900"
      >
        <Header isAdminPage = { true } />

        <main
          className = "flex-grow md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full"
        >
          {children}
        </main>

        <Footer />
      </div>
    </div>

  );
}

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default AdminLayout;
