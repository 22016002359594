import React, { useState } from "react";
import { navigate } from "gatsby";
import Grid from "../grid";
import { penaltyUnitLegislationIllustrations, defaultPenaltyUnitLegislationIllustration } from "../../utils/gridImages";
import { getFinancialYearFirstYear } from "../../utils/helpers";
import GoBack from "../goBack";

const PenaltyUnitsJurisdictionComponent = (props) =>
{
  // Name, PUMs
  const jurisdiction = props.jurisdictionQueryResult
    ? { ...props.jurisdictionQueryResult.data.jurisdictionById }
    : {}
  ;

  const [jurisdictionName, setJurisdictionName] = useState(jurisdiction.name);
  const [validation, setValidation] = useState(
    {
      isJurisdictionNameValid: true,
    }
  );

  if (props.jurisdictionQueryResult)
  {
    for (let i = 0; i < jurisdiction.penaltyUnitMultipliers.length; ++i)
    {
      const commencementDateFinancialYearFirstYear = getFinancialYearFirstYear(jurisdiction.penaltyUnitMultipliers[i].commencementDate);
  
      jurisdiction.penaltyUnitMultipliers[i].name = `${jurisdiction.penaltyUnitMultipliers[i].authorityName}`;
      jurisdiction.penaltyUnitMultipliers[i].description = `${commencementDateFinancialYearFirstYear} - ${commencementDateFinancialYearFirstYear + 1}`;
      jurisdiction.penaltyUnitMultipliers[i].link = `/tools/penaltyunits/jurisdiction/legislation?j=${jurisdiction.id}&pu=${jurisdiction.penaltyUnitMultipliers[i].id}`;
    }
  
    const addPenaltyUnitLegislationGridElement =
    {
      id: 999,
      name: "Add Legislation",
      description: "Create a new Penalty Unit entry.",
      link: `/tools/penaltyunits/jurisdiction/legislation?j=${jurisdiction.id}`
    };
  
    jurisdiction.penaltyUnitMultipliers = [addPenaltyUnitLegislationGridElement].concat(jurisdiction.penaltyUnitMultipliers);
  }

  const handleSubmit = (e) =>
  {
    e.preventDefault();

    let localIsJurisdictionNameValid = true;

    if (!jurisdictionName)
    {
      localIsJurisdictionNameValid = false;
    }

    setValidation(
      {
        isJurisdictionNameValid: localIsJurisdictionNameValid
      }
    );

    if (localIsJurisdictionNameValid)
    {
      if (props.jurisdictionCreationMode())
      {
        props.createJurisdiction(
          jurisdictionName
        );
      }
      else
      {
        props.updateJurisdiction(
          props.jurisdictionId,
          jurisdictionName
        );
      }

      navigate(`/admin/tools/penaltyunits`);
    }
  };

  const handleDelete = (e) =>
  {
    e.preventDefault();

    if (!confirm("Deletion of this jurisdion will wipe out all attached penalty unit legislation.\n\nContinue?"))
    {
      return;
    }

    props.deleteJurisdiction(
      props.jurisdictionId
    );

    navigate("/admin/tools/penaltyunits");
  };

  return (
    <section
      id = "utilitySection"
    >
      <GoBack
        text = "← Back to Jurisdictions"
        destination = "/admin/tools/penaltyunits"
      />
      <div className = "text-center">
        <h2 className = "bg-yellow-400 text-2xl font-bold inline-block mb-8 p-3">
          Jurisdiction Information
        </h2>
      </div>
      <form>
        <div
          className = "mb-6"
        >
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="jurisdictionName"
          >
            Name
          </label>

          <div
            className = "px-3 py-2"
          >
            <input
              id = "jurisdictionName"
              className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
              type = "text"
              defaultValue = { jurisdictionName }
              onChange = { e => { setJurisdictionName(e.target.value); } }
            />
          </div>

          {
            !validation.isJurisdictionNameValid &&
            <p
              className = "px-3 text-red-500 text-xs italic"
            >
              Please enter the Jurisdiction's Name.
            </p>
          }
        </div>
        {
          props.jurisdictionCreationMode() &&
            <div
              className = "text-center sm:text-left"
            >
              <button
                className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 mr-2 rounded text-sm text-white"
                type = "button"
                onClick = { e => handleSubmit(e) }
              >
                Create
              </button>
            </div>
        }
        {
          !props.jurisdictionCreationMode() &&
            <div
              className = "text-center sm:text-left"
            >
              <button
                className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 mr-2 rounded text-sm text-white"
                type = "button"
                onClick = { e => handleSubmit(e) }
              >
                Update
              </button>
            </div>
        }
      </form>
      {
        props.jurisdictionQueryResult &&
          <div>
            <div className = "text-center">
              <h2 className = "bg-yellow-400 text-2xl font-bold inline-block mb-8 p-3">
                Legislations
              </h2>
            </div>
            <div className = "text-center mb-8">
              <Grid
                gridElements = { jurisdiction.penaltyUnitMultipliers }
                elementImages = { penaltyUnitLegislationIllustrations }
                defaultElementImage = { defaultPenaltyUnitLegislationIllustration }
                isAdminPage = { true }
              />
            </div>
            <div className = "text-center">
              <h2 className = "bg-yellow-400 text-2xl font-bold inline-block mb-8 p-3">
                Danger Zone
              </h2>
            </div>
            <div
              className = "text-center sm:text-left"
            >
              <button
                className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 mr-2 rounded text-sm text-white"
                type = "button"
                onClick = { e => handleDelete(e) }
              >
                Delete Jurisdiction
              </button>
            </div>
          </div>
      }
    </section>
  );
};

export default PenaltyUnitsJurisdictionComponent;