import React from "react";
import Moment from "moment";

export const pad = (n, width, z) =>
{
  z = z || '0';
  n = n + '';
  
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const isValidHttpUrl = (string) =>
{
  let url;

  try
  {
    url = new URL(string);
  }
  catch (_)
  {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const getFinancialYearFirstYear = (dateString) =>
{
  const date = Moment(dateString, "YYYY-MM-DD");
  const dateYear = date.year();

  return date.isBefore(`${dateYear}-07-01`) ? dateYear - 1 : dateYear;
};

export const isClassComponent = (component) =>
{
  return typeof component === 'function' && !!component.prototype.isReactComponent;
};

export const isFunctionComponent = (component) =>
{
  return typeof component === 'function' && String(component).includes('return React.createElement');
};

export const isReactComponent = (component) =>
{
  return isClassComponent(component) || isFunctionComponent(component);
};

export const isElement = (element) =>
{
  return React.isValidElement(element);
};

export const isDOMTypeElement = (element) =>
{
  return isElement(element) && typeof element.type === 'string';
};

export const isCompositeTypeElement = (element) =>
{
  return isElement(element) && typeof element.type === 'function';
};