import React from "react";
import GridElement from "../components/gridElement.js";

const Grid = (props) =>
{
  const getImage = (elementId) =>
  {
    const elementImage = props.elementImages.find(i => i.id === elementId);

    return (elementImage && elementImage.image)
      ? elementImage.image
      : props.defaultElementImage;
  };

  return (
    <div
      className = "flex flex-wrap"
    >
      {
        props.gridElements.map(
          t => (
            <GridElement
              key = { t.id }
              image = { getImage(t.id) }
              title = { t.name }
              description = { t?.description ?? '' }
              link = { props.isAdminPage ? `/admin${t.link}` : t.link }
            />
          )
        )
      }
    </div>
  );
}

export default Grid;
