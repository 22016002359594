import React from "react";
import { navigate } from "gatsby";
import AdminLayout from "../../../../../components/admin/adminLayout";
import PenaltyUnitsJurisdictionComponent from "../../../../../components/admin/penaltyUnitsJurisdictionComponent";
import Apollo from "../../../../../components/apollo";
import
{
  penaltyUnitCalculatorQuery,
  getJurisdictionById,
  jurisdictionCreateMutation,
  jurisdictionUpdateMutation,
  jurisdictionDeleteMutation
} from "../../../../../utils/staticQueryFragments";
import SEO from "../../../../../components/seo";
import WithPrivateRoute from "../../../../../components/admin/withPrivateRoute";
import WithLocation from "../../../../../components/withLocation";
import PenaltyUnitsJurisdictionSkeleton from "../../../../../components/admin/penaltyUnitsJurisdictionSkeletonComponent";

const ToolsPenaltyUnitsJurisdiction = (props) =>
{
  const jurisdictionCreationMode = () =>
  {
    return !props.search.j;
  };

  const getJurisdictionId = () =>
  {
    return props.search.j;
  };

  return (
    <AdminLayout>
      <SEO
        keywords = { [`LawChest`, `admin`, `tools`, `administration`, `penalty`, `unit`, `calculator`, `jurisdiction`] }
        title = "Penalty Unit Calculator Jurisdiction"
        isAdmin = { true }
      />
      <Apollo
        propsToInsert =
        {
          (
            queryResults,
            { mutation1, mutation1Results },
            { mutation2, mutation2Results },
            { mutation3, mutation3Results }
          ) => (
            {
              jurisdictionQueryResult: queryResults,
              createJurisdiction: (name) =>
              {
                mutation1(
                  {
                    variables:
                    {
                      name: name
                    }
                  }
                );
              },
              createJurisdictionMutationResult: mutation1Results,
              updateJurisdiction: (id, name) =>
              {
                mutation2(
                  {
                    variables:
                    {
                      id: id,
                      name: name
                    }
                  }
                );
              },
              deleteJurisdiction: (id) =>
              {
                mutation3(
                  {
                    variables:
                    {
                      id: id
                    }
                  }
                );
              }
            }
          )
        }
        query =
        {
          !jurisdictionCreationMode()
            ? getJurisdictionById
            : undefined
        }
        queryOptions =
        {
          !jurisdictionCreationMode()
          ?
            {
              variables: { id: getJurisdictionId() }
            }
          : undefined
        }
        mutation1 = { jurisdictionCreateMutation }
        mutation1Options =
        {
          {
            update(cache, { data: { createJurisdiction } })
            {
              const cachedPenaltyUnitCalculatorQueryData = cache.readQuery(
                {
                  query: penaltyUnitCalculatorQuery
                }
              );

              cache.writeQuery(
                {
                  query: penaltyUnitCalculatorQuery,
                  data:
                  {
                    allJurisdictions: [
                      {
                        __typename: createJurisdiction.__typename,
                        id: createJurisdiction.id,
                        name: createJurisdiction.name,
                        penaltyUnitMultipliers: [
                          ...createJurisdiction.penaltyUnitMultipliers
                        ]
                      },
                      ...cachedPenaltyUnitCalculatorQueryData.allJurisdictions
                    ]
                  }
                }
              );
            }
          }
        }
        mutation2 = { jurisdictionUpdateMutation }
        mutation3 = { jurisdictionDeleteMutation }
        mutation3Options =
        {
          {
            update(cache, { data: { deleteJurisdiction } })
            {
              const cachedPenaltyUnitCalculatorQueryData = cache.readQuery(
                {
                  query: penaltyUnitCalculatorQuery
                }
              );

              cache.writeQuery(
                {
                  query: penaltyUnitCalculatorQuery,
                  data:
                  {
                    allJurisdictions: [
                      ...cachedPenaltyUnitCalculatorQueryData.allJurisdictions.filter(j => j.id !== deleteJurisdiction.id)
                    ]
                  }
                }
              );
            }
          }
        }
        loadingComponent = { PenaltyUnitsJurisdictionSkeleton }
      >
        <PenaltyUnitsJurisdictionComponent
          jurisdictionId = { getJurisdictionId() }
          jurisdictionCreationMode = { jurisdictionCreationMode }
        />
      </Apollo>
    </AdminLayout>
  );
};

export default WithPrivateRoute(WithLocation(ToolsPenaltyUnitsJurisdiction));