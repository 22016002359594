import React from "react";
import { Link } from "gatsby";

const GoBack = props =>
{
  return (
    <Link
      className = "font-bold text-xs uppercase"
      to = { props.destination }
    >
      { props.text ?? "← Go Back" }
    </Link>
  );
};

export default GoBack;