import React from "react";
import { navigate } from "gatsby";
import { isBrowser, isLoggedIn } from "../../utils/auth";

const WithPrivateRoute = ComponentToWrap => (
  (props) =>
  {
    if (isLoggedIn())
    {
      return (
        <ComponentToWrap
          { ...props }
        />
      );
    }

    if (isBrowser())
    {
      navigate("/admin/login");
    }

    return null;
  }
);

export default WithPrivateRoute