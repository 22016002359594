import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { NetworkStatus } from "apollo-boost";

const Apollo = (props) =>
{
  const [refetchCount, setRefetchCount] = useState(0);

  const [mutation1, mutation1Results] = props.mutation1 ? useMutation(props.mutation1, props.mutation1Options) : [];
  const [mutation2, mutation2Results] = props.mutation2 ? useMutation(props.mutation2, props.mutation2Options) : [];
  const [mutation3, mutation3Results] = props.mutation3 ? useMutation(props.mutation3, props.mutation3Options) : [];

  let queryResults;

  if (props.query)
  {
    queryResults = useQuery(props.query, props.queryOptions);

    if (queryResults.loading || queryResults.networkStatus === NetworkStatus.refetch)
    {
      if (props.loadingComponent)
      {
        return <props.loadingComponent />;
      }

      return "Loading...";
    }
    
    if (queryResults.error)
    {
      if (refetchCount > 3)
      {
        return "Failed to Fetch Data.  Please refresh the webpage.";
      }

      setRefetchCount(refetchCount + 1);

      queryResults.refetch();
      
      if (props.loadingComponent)
      {
        return <props.loadingComponent />;
      }

      return "Loading...";
    }
  
    if (!queryResults.data)
    {
      return "No Data...";
    } 
  }

  return React.cloneElement(
    props.children,
    props.propsToInsert(
      queryResults,
      {
        mutation1: mutation1,
        mutation1Results: mutation1Results
      },
      {
        mutation2: mutation2,
        mutation2Results: mutation2Results
      },
      {
        mutation3: mutation3,
        mutation3Results: mutation3Results
      }
    )
  );
};

export default Apollo;