import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const GridElement = ({ image, title, description, link }) =>
{
  return (
    <div
      className = "w-full sm:w-1/2 md:w-1/3 lg:w-1/4 my-2"
    >
      <div
        className = "h-full m-2 p-2 bg-gray-200 hover:bg-gray-300"
      >
        <Link
          className = ""
          to = { link }
        >
          <img
            alt = "Balance"
            className = "fill-current mx-auto"
            height = "81"
            viewBox = "0 0 81 81"
            width = "81"
            src = { image }
          />
          <p
            className = "text-lg font-semibold"
          >
            { title }
          </p>
          <p
            className = "text-sm font-normal"
          >
            {
              description.length <= 100 ?
                description
                : description.substring(0, 100) + "..."
            }
          </p>
        </Link>
      </div>
    </div>
  );
}

GridElement.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
};

export default GridElement;
