import { graphql } from "gatsby";
import { gql } from "apollo-boost";

export const penaltyUnitCalculatorQuery = gql`
  query getAllJurisdictions
  {
    allJurisdictions
    {
      id
      name
      penaltyUnitMultipliers
      {
        id
        commencementDate
        value
        authorityName
        authorityLink
      }
    }
  }
`;

export const getJurisdictionById = gql`
  query getJurisdictionById($id: ID!)
  {
    jurisdictionById(id: $id)
    {
      id
      name
      penaltyUnitMultipliers
      {
        id
        jurisdictionId
        commencementDate
        value
        authorityName
        authorityLink
      }
    }
  }
`;

export const loginUserMutation = gql`
  mutation LoginUser($email: String!, $password: String!)
  {
    loginUser(email: $email, password: $password)
    {
      email
      token
    }
  }
`;

export const updateUserMutation = gql`
  mutation UpdateUser($currentEmail: String!, $currentPassword: String!, $newEmail: String!, $newPassword: String!)
  {
    updateUser(currentEmail: $currentEmail, currentPassword: $currentPassword, newEmail: $newEmail, newPassword: $newPassword)
    {
      email
      token
    }
  }
`;

export const penaltyUnitCalculatorLegislationQuery = gql`
  query getPenaltyUnitMultiplierById($id: ID!)
  {
    penaltyUnitMultiplierById(id: $id)
    {
      id
      jurisdictionId
      commencementDate
      value
      authorityName
      authorityLink
    }
  }
`;

export const jurisdictionCreateMutation = gql`
  mutation createJurisdiction($name: String!)
  {
    createJurisdiction(name: $name)
    {
      id
      name
      penaltyUnitMultipliers
      {
        id
        jurisdictionId
        commencementDate
        value
        authorityName
        authorityLink
      }
    }
  }
`;

export const jurisdictionUpdateMutation = gql`
  mutation updateJurisdiction($id: ID!, $name: String!)
  {
    updateJurisdiction(id: $id, name: $name)
    {
      id
      name
      penaltyUnitMultipliers
      {
        id
        jurisdictionId
        commencementDate
        value
        authorityName
        authorityLink
      }
    }
  }
`;

export const jurisdictionDeleteMutation = gql`
  mutation deleteJurisdiction($id: ID!)
  {
    deleteJurisdiction(id: $id)
    {
      id
      name
      penaltyUnitMultipliers
      {
        id
        jurisdictionId
        commencementDate
        value
        authorityName
        authorityLink
      }
    }
  }
`;

export const penaltyUnitCalculatorLegislationCreateMutation = gql`
  mutation createPUCL(
    $jurisdictionId: ID!,
    $commencementDate: String!,
    $value: Float!,
    $authorityName: String,
    $authorityLink: String
  )
  {
    createPenaltyUnitMultiplier(
      jurisdictionId: $jurisdictionId,
      commencementDate: $commencementDate,
      value: $value,
      authorityName: $authorityName,
      authorityLink: $authorityLink
    )
    {
      id
      commencementDate
      value
      authorityName
      authorityLink
    }
  }
`;

export const penaltyUnitCalculatorLegislationUpdateMutation = gql`
  mutation updatePUCL(
    $id: ID!,
    $commencementDate: String!,
    $value: Float!,
    $authorityName: String,
    $authorityLink: String
  )
  {
    updatePenaltyUnitMultiplier(
      id: $id,
      commencementDate: $commencementDate,
      value: $value,
      authorityName: $authorityName,
      authorityLink: $authorityLink
    )
    {
      id
      commencementDate
      value
      authorityName
      authorityLink
    }
  }
`;

export const penaltyUnitCalculatorLegislationDeleteMutation = gql`
  mutation deletePUCL(
    $id: ID!
  )
  {
    deletePenaltyUnitMultiplier(
      id: $id,
    )
    {
      id
      commencementDate
      value
      authorityName
      authorityLink
    }
  }
`;

export const suggestToolMutation = gql`
  mutation createToolSuggestion(
    $name: String,
    $email: String,
    $suggestion: String!
  )
  {
    createToolSuggestion(
      name: $name,
      email: $email,
      suggestion: $suggestion
    )
    {
      id
    }
  }
`;